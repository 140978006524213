<template>
    <div class="scholarship">
        <div class="scholarship-header">
            <slot name="header"></slot>
            <!--<div v-if="goToScholarshipPage">
              <a :href="goToScholarshipPage" class="apply">
                <span>{{ $t('scholarshipSubmit') }}</span>
                <img src="@/assets/images/arrow-right.svg" />
              </a>
            </div>
            <div v-else>
              <apply-button class="display-apply_btn" />
            </div>-->
        </div>

        <div class="scholarship-content">
            <p class="content-title">I — {{ $t('program') }}</p>
            <slot name="content"></slot>
        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex";
// import {mapActions, mapGetters} from "vuex";
// import ApplyButton from "@/components/apply-button";

export default {
    components: {
        //ApplyButton,
    },
    computed: {
        ...mapGetters('applications', ['formattedScholarships']),
        goToScholarshipPage() {
          let route
          switch (this.$route.path) {
            case '/scholarships/Ostrogradski':
              route = this.$t('ostrogradskiUrl');
              break;
            case '/scholarships/Vernadski':
              route = this.$t('vernadskiUrl');
              break;
            case '/scholarships/Metchnikov':
              route = this.$t('metchnikovUrl');
              break;
            default:
                route = ''
          }

          return route
        }
    },
    async mounted() {
//        if(!this.formattedScholarships) {
//            await this.getAllScholarships()
//        }
    },
    methods: {
//        ...mapActions('applications',['getAllScholarships']),
    }

}
</script>
<style lang="scss" scoped>
.apply {
  width: 240px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0045FF;
  color: #FFFFFF!important;
  text-decoration: none!important;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  border-radius: 6px;
  cursor: pointer;
  img {
    margin-left: 10px;
  }

  &-disabled {
    background-color: #abadb1;
    pointer-events: none;
    @media(max-width: 768px) {
      display: none;
    }
  }

  &_wrap {
    height: 150px;
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.display-apply_btn {
    position: fixed;
    right: 210px;
    @media (max-width: 1870px) {
        right: 150px;
    }
    @media (max-width: 1750px) {
        right: 120px;
    }
    @media (max-width: 1690px) {
        right: 80px;
    }
    @media (max-width: 1610px) {
        right: 30px;
    }
    @media (max-width: 1440px) {
        display: none;
    }
}
.mobile-table_wrapper {
    @media (max-width: 1024px) {
        margin-left: -373px;
        width: calc(100% + 373px);
        margin-top: 140px;
    }
    @media (max-width: 768px) {
        margin: 0;
        width: 100%;
    }
}
.scholarship {
    &-header {
        display: flex;
        justify-content: space-between;

        h1 {
            font-style: normal;
            font-weight: 900;
            font-size: 36px;
            line-height: 52px;
            max-width: 800px;

            @media (max-width: 1024px) {
                font-size: 22px;
                line-height: 34px;
            }
        }
    }

    &-content {
        .content-title {
            font-weight: bold;
            font-size: 22px;
            line-height: 34px;
            margin: 15px 0 35px;
        }
        .content-text {
            width: calc(100% - 280px);
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            margin-top: 15px;
            @media(max-width: 1440px) {
                width: 100%;
            }
        }
    }



.table-content {

    @media(max-width: 600px) {
        width: 90vw;
        overflow: hidden;
    }

    .table-wrap {
        width: calc(100% - 280px);

        @media(max-width: 1440px) {
            width: 100%;
        }
        @media(max-width: 600px) {
            width: 700px;
            overflow-x: scroll;
        }
    }

    .table-row {
        @media(max-width: 600px) {
            width: 900px;
        }
        @media(max-width: 480px) {
            width: 1000px;
        }
        @media(max-width: 460px) {
            width: 1050px;
        }
    }

}

    .table-title {
        margin: 40px 0 20px;
    }

    .table-row {
        display: flex;
        padding-bottom: 15px;
        border-bottom: 1px solid #808080;
        margin-bottom: 20px;

        &-title {
            width: 33%;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            padding: 0 30px 0 0;

            @media(max-width: 600px) {
                width: 220px;
            }
        }

        &-item {
            margin-top: 10px;
            width: 33%;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            padding: 0 30px 0 0;

            @media(max-width: 600px) {
                width: 220px;
            }

            p {
                margin-bottom: 15px;
            }
        }
    }

    .file-wrap {
        width: calc(100% - 280px);
        display: flex;
        align-items: flex-start;
        margin: 40px 0;

        @media(max-width: 1440px) {
            width: 100%;
        }

        p {
            margin-left: 20px;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
        }

        .email {
            color: #0045FF;
        }
    }

    .info-wrap {
        width: calc(100% - 280px);
        display: flex;
        margin-bottom: 60px;

        @media(max-width: 1440px) {
            width: 100%;
        }

        @media (max-width: 600px) {
            flex-direction: column;
        }

        div {
            width: 50%;
            @media (max-width: 600px) {
                width: 100%;
            }

            img {
                @media (max-width: 768px) {
                    width: 300px;
                }
                @media (max-width: 600px) {
                    width: 280px;
                    display: block;
                    margin: 0 auto 20px;
                }
            }
        }

        .info-img-wrap {
            margin-right: 10px;
        }

        p {
            font-size: 16px;
            line-height: 24px;
        }
        .name {
            font-weight: bold;
        }
        .info {
            font-weight: normal;
        }
    }
}
</style>
