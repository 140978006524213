<template>
    <div>
        <scholarship-page>
            <template v-slot:header>
                <div>
                    <Breadcrumbs
                        :items="items"
                    />
                    <h1>{{ $t('metchnikovTitle') }}</h1>
                    <h2 class="sec-title">{{ $t('metchnikovCalendar') }}</h2>
                    <h3 v-html="$t('howToApplyMetchnikov')"></h3>
                </div>
            </template>

            <template v-slot:content>
                <p class="content-text" v-html="$t('metchnikovProgramText')"></p>

            <div class="mobile-table_wrapper metchnikov-table_wrapper">
                <p class="content-title table-title">II — {{ $t('forCandidats') }}</p>

                <div class="table-content">
                    <div class="table-wrap">
                        <div class="table-row">
                            <div class="table-row-title">{{ $t('columnTitle1') }}</div>
                            <div class="table-row-title">{{ $t('columnTitle2') }}</div>
                            <div class="table-row-title">{{ $t('columnTitle3') }}</div>
                        </div>
                        <div class="table-row">
                            <div class="table-row-item">
                                <p>{{ $t('metchnikovTableItem1') }}</p>
                                <p>{{ $t('metchnikovTableItem2') }}</p>
                            </div>
                            <div class="table-row-item">
                                <p v-if="$t('metchnikovTableItem3')">{{ $t('metchnikovTableItem3') }}</p>
                                <p v-html="$t('formMetchnikov')"></p>
                                <p>{{ $t('passport') }}</p>
                                <p>{{ $t('cv') }}</p>
                                <p>{{ $t('metchnikovTableItem4') }}</p>
                                <p>{{ $t('metchnikovTableItem5') }}</p>
                                <p>{{ $t('metchnikovTableItem6') }}</p>
                            </div>
                            <div class="table-row-item">
                                <p>{{ $t('visa') }}</p>
                                <p v-html="$t('metchnikovTableItem7')"></p>
                                <p>{{ $t('metchnikovTableItem8') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info-wrap">
                    <div class="info-img-wrap">
                        <img src="@/assets/images/Metchnikov.png"/>
                    </div>
                    <div>
                        <p class="name">{{ $t('metchnikovName') }}</p>
                        <p class="info">
                            {{ $t('metchnikovDescription') }}
                        </p>
                    </div>
                </div>
            </div>
            </template>
        </scholarship-page>
    </div>
</template>

<script>
import ScholarshipPage from "@/components/ScholarshipPage";
import Breadcrumbs from '@/components/breadcrumbs';

export default {
    components: {
        ScholarshipPage,
        Breadcrumbs
    },

    data: () => ({
        items: [
            {
                text: 'home',
                href: '/'
            },
            {
                text: 'boursesEtudes',
                href: '#',
            },
            {
                text: 'metchnikov',
                href: '#',
            }
        ]
    }),

}
</script>

<style lang="scss">
.sec-title {
  line-height: 30px;
  padding: 10px 0px;
}
.metchnikov-table_wrapper {
    @media(max-width: 1024px) {
        margin-top: 280px!important;
    }
    @media (max-width: 768px) {
        margin: 0!important;
    }
}
</style>